<!--产品分类-->
<template>
  <div class="goods">
    <Search class="" navType="search" :sty="topStyle" :carIcon="false"></Search>
    <div class="goods-sort">
      <!--左侧内容-->
      <div class="wrapper" ref="wrapper">
        <div class="sort-left">
          <ul>
            <li
              v-for="(item, idx) in firstList"
              :key="idx"
              ref="listGroup"
              @click="changItem(item.id, idx)"
              :class="leftIdx === idx ? 'active-li':''"
            >
              <div :class="leftIdx === idx ? 'active':''">{{item.name}}</div>
            </li>
          </ul>
        </div>
      </div>
      <!--右侧内容-->
      <div class="sort-main" ref="right">
        <div class="sort-main-con" :container="container">
          <Loading v-if="loadingStatus"></Loading>
          <div class="ad-pos">
            <Swipe class="my-swipe" :autoplay="3000" indicator-color="white">
              <template v-if="firstList.length">
                <swipe-item v-for="(item,index) in firstList[leftIdx].image_ids_url" :key="index">
                  <div>
                    <img :src="item.url" alt="" class="swipe_img">
                  </div>
                </swipe-item>
              </template>
            </Swipe>
          </div>
          <div class="sort-main-right">
            <Sticky
              :z-index="500"
              :offset-top="80"
              :container="container"
              class="sort-main-title"
            >{{mainObj.name}}..</Sticky>
            <div class="sort-main-item-con">
              <div
                class="sort-main-item"
                v-for="(child, rightIdx) in mainObj.child"
                :key="rightIdx"
                v-lazy="child"
                @click="goGoodsList(child)"
              >
                <ImageItem
                  width="60"
                  height="75"
                  :src="child.image[0]"
                  alt
                />
                <span>{{child.name}}</span>
              </div>
            </div>
          </div>
          <Loading v-if="loadingStatus"></Loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Image, Sticky, Swipe, SwipeItem } from "vant";
import BScrollObj from "better-scroll";
import SysConfig from "@/api/config";
import Goods from '@/api/goods';
import Loading from "@components/effects/loading";
import Search from "@components/public/searchTop";
export default {
  components: {
    Loading,
    Search,
    ImageItem: Image,
    Sticky,
    Swipe,
    SwipeItem
  },
  data() {
    return {
      topStyle: {
        background: "#ffffff",
        color: "#333333"
      },

      firstList: [],
      mainObj: {},
      nowIdx: Number,

      rightCon: {},
      scroolCon: {},
      Items: [{}],
      leftIdx: 0,
      historyIdx: [],
      container: null,
      loadingStatus: false,
      pullUpLoad: {
        threshold: 50
      }
    };
  },
  computed: {
    numArr: function() {
      let arr = [];
      for (let i = 0; i < 20; i++) {
        let obj = {
          name: "标题" + i,
          child: [
            {
              name: "二级分类"
            },
            {
              name: "二级分类"
            },
            {
              name: "二级分类"
            },
            {
              name: "二级分类"
            }
          ]
        };
        arr.push(obj);
      }
      return arr;
    }
  },
  mounted() {
    setTimeout(() => {
      this._initScroll();
      // 一级列表
      this.getGoodsList();
      // 二级列表
      setTimeout(() => {
        this.getGoodsItemList(this.firstList[0].id);
      }, 800)
      this.container = this.$refs.container;
    }, 20);
  },
  methods: {
    goGoodsList(items) {
      this.$router.push('/search?id=' + items.id );
      console.log(items);
    },
    _initScroll() {
      // 初始化better-
      this.$nextTick(() => {
        // 初始化内容
        this.rightCon = new BScrollObj(this.$refs.right, {
          click: true,
          pullUpLoad: {
            threshold: -50
          },
          pullDownRefresh:  {
            threshold: 45,
            probeType: 2
          },
        });
        // 初始化左侧导航栏
        this.scroolCon = new BScrollObj(this.$refs.wrapper, {
          click: true,
          pullUpLoad: this.pullUpLoad
        });
        // 获取列表
        this.getUpList();
      });
    },
    // 左侧导航Idx
    changItem(id, num) {
      this.nowIdx = id;
      this.leftIdx = num;
      this.getGoodsItemList(id);
    },
    // 一级商品列表
    async getGoodsList() {
      try {
        const res = await SysConfig.getCate();
        if (res.code == 200) {
          this.firstList = res.data;
        }
        console.log(res);
        setTimeout(() => {
          this.loadingStatus = false;
        }, 1000)
      } catch (err) {
        console.log(err)
      }
    },
    // 获取全部分类列表
    async getAlllist() {
      try {
        const res = await Goods.getGoodsTree();
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 二级商品列表
    async getGoodsItemList(id) {
      try {
        const res = await SysConfig.getCateChild(id);
        if (res.code == 200) {
          this.mainObj = res.data;
          console.log(this.mainObj);
        }
        console.log(res);
        setTimeout(() => {
          this.loadingStatus = false;
        }, 1000)
      } catch (err) {
        console.log(err)
      }
    },
    // 加载数据
    getUpList() {
      this.rightCon.on('pullingDown', () => {
        this.loadingStatus = true;
        // 加载列表
        try {
          let numIdx = this.leftIdx --;
          if (numIdx == 0) {
            this.leftIdx = 0;
            throw("已经到底了...");
          } 
          this.getGoodsItemList();
          this.$nextTick(() => {
            this.rightCon.refresh();
          })
        } catch (err) {
          this.loadingStatus = false;
          this.$toast(err);
        }
        this.rightCon.finishPullDown();
      })
      this.rightCon.on('pullingUp', () => {
        this.loadingStatus = true;
        console.log('获取下拉加载数据....');
        this.leftIdx ++;
        this.getGoodsItemList();
        this.$nextTick(() => {
          this.rightCon.refresh();
        })
        this.rightCon.finishPullUp();
      })
    },
    // 右侧点击
    selectItem(idx) {
      console.log('当前Idx为', idx);
    }
  }
};
</script>
<style lang="scss" scoped>
.goods {
  width: 100%;
  height: 100vh;
  background: #f4f4f4;
  .nav-top {
    width: 100%;
    height: 45px;
  }
  .goods-sort {
    width: 100%;
    height: 93vh;
    position: absolute;
    left: 0;
    top: 45px;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    .sort-left {
      width: 100%;
      flex: 0 0 80px;
      ul {
        width: 100%;
        li {
          width: 100%;
          height: 45px;
          font-size: 14px;
          text-align: center;
          line-height: 45px;
          display: flex;
          align-items: center;
          div {
            width: 100%;
            height: 16px;
            line-height: 16px;
          }
          .active {
            width: 100%;
            color: #ff8000;
            border-left: 1px solid #ff8000;
          }
        }
        .active-li {
          width: 100%;
          background: #ffffff;
        }
      }
    }
  }
  .sort-main {
    width: 76%;
    height: 94vh;
    position: relative;
    margin-left: -10px;
    background: #ffffff;
    .ad-pos {
      width: 100%;
      font-size: 25px;
      line-height: 100px;
      color: #ffffff;
      text-align: center;
      // background: #ff8000;
      padding: 10px;
      div{
        border-radius: 5px;
        width:100%;
        height: 90px;
      }
      .swipe_img{
        width:100%;
        height: 90px;
      }
    }
    .sort-main-con {
      width: 100%;
      position: relative;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      .sort-main-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        .sort-main-title {
          width: 100%;
          padding-left: 10px;
          font-size: 14px;
          color: #333333;
          line-height: 30px;
          // background: red;
        }
        .sort-main-item-con {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .sort-main-item {
            width: 28%;
            height: 100px;
            margin-top: 10px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            flex-direction: column;
            span {
              display: inline-block;
              padding-top: 4px;
            }
          }
        }
      }
    }
  }
}

.wrapper {
  width: 24%;
  height: 100vh;
  .content {
    width: 100%;
    height: auto;
    li {
      width: 100%;
      height: 90px;
      text-align: center;
      border-bottom: 1px solid #dfdfdf;
    }
  }
}
</style>